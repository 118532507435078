@import './../../foundation/variables';
@import './../../foundation/mixin';


.y24vol01{
	color: #A8B400;
}

.y24vol02{
	color: #E5491C;
}

.y24vol03{
	color: #98236C;
}

.y24vol04{
	color: #00A7D8;
}

.-hc-color{
	color: #9f8b49;
}

.-is-24vol01,
.-is-24vol02,
.-is-24vol03,
.-is-24vol04{
	.hc-l-header{
		padding-top: 23px;
		padding-bottom: 8px;
		@include mqPc {
			padding-top: 38px;
			padding-bottom: 34px;
		}
	}
}

body{
	&.-hc{
		.c-program__list{
			@include mqPc {
				margin-top: 17px;
			}
		}
	}
}


.l-wrapper{
	font-feature-settings: "palt";
}
.l-main{
	@include mqPc {
		padding-top: 28px;
	}
}
.hc-l-header{
	padding: 19px 24px 24px;
	@include mqPc {
		padding: 36px 19px 22px;
	}
	.hc-p-header-logo {
		width: 139px;
		@include mqPc {
			padding-left: 35px;
			padding-right: 35px;
			width: 618px;
		}
	}
	&.is-move::after{
		height: 100%;
		@include mqPc {
			height: calc(100% - 28px);
		}
	}
}
.hc-l-header.is-open .hc-p-header-navigation__toggle{
	@include mqPc {
	top: 27px;
	}
	&::before{
		transform: rotate(45deg) translate(-1px, -1px);
	}
}

.hc-p-header-navigation{
	&__toggle{
		top: 18px;
    right: 16px;
		@include mqPc {
			top: 27px;
			right: 26px;
			height: 48px;
		}
	}
	&__item{
		picture,
		img{
			display: block;
		}
		&--top{
			picture,
			img{
				width: calc(86px /2);
				height: auto;
				@include mqPc {
					width: 41px;
				}
			}
		}
		&--vol1{
			picture,
			img{
				width: 216.5px;
				height: auto;
				@include mqPc {
					width: 205px;
				}
			}
		}
		&--vol2{
			picture,
			img{
				width: 245.5px;
				height: auto;
				@include mqPc {
					width: 267px;
				}
			}
		}
		&--vol3{
			picture,
			img{
				width: 248.5px;
				height: auto;
				@include mqPc {
					width: 290px;
				}
			}
		}
		&--vol4{
			picture,
			img{
				width: 232px;
				height: auto;
				@include mqPc {
					width: 290px;
				}
			}
		}
		&--access{
			picture,
			img{
				width: calc(144px /2);
				height: auto;
				@include mqPc {
					width: 68px;
				}
			}
		}
		&--ongakudo{
			picture,
			img{
				width: calc(251px /2);
				height: auto;
				@include mqPc {
					width: 118px;
				}
			}
		}
		&--archive{
			picture,
			img{
				width: calc(166px /2);
				height: auto;
				@include mqPc {
					width: 68px;
				}
			}
		}
	}
	&__link{
		padding: 21.4px 12.5px;
		@include mqPc {
			padding: 22px 10px;
		}
	}
}

.l-footer{
	.l-footer__inner {
		padding-top: 14px;
		@include mqPc {
			padding-top: 88px;
			padding-bottom: 58px;
		}
	}
}

.u-pc-flex{
	@include mqPc {
		display: flex;
	}
}

.u-border--none{
	text-decoration: none;
}


.c-lead__title,
.c-about__text,
.c-performers__item,
.c-program__item p,
.c-program__item span,
.c-profile__text,
.c-tickets__heading,
.c-tickets__heading small,
.c-tickets__heading--sub,
.c-tikets__text--note,
.c-tickets__box p,
.c-tickets__box span,
.c-tickets__list--dots,
.c-section__contents .c-text{
	letter-spacing: normal;
}


.c-lead__title{
	padding-top: 15px;
	@include mqPc {
		padding-top: 4px;
	}
	&+.c-buttons{
		margin-top: 60px;
		@include mqPc {
			margin-top: 41px;
		}
	}
}

.c-buttons{
	.c-button{
		width: 82%;
    max-width: 82%;
		@include mqPc {
			width: calc(50% - 30px);
			max-width: calc(50% - 30px);
		}
		&:nth-of-type(n+2) {
			margin-top: 31px;
			@include mqPc {
				margin-top: 0;
			}
		}
	}
}

.c-section{
	&__heading{
		&::after{
			margin-left: 3px;
			@include mqPc {
				margin-left: 11px;
			}
		}
	}
	&--news {
		margin-top: 73px;
		@include mqPc {
			margin-top: 75px;
		}
		.c-section__contents{
			margin-top: 16px;
			@include mqPc {
				margin-top: 22px;
			}
		}
	}
	&--about {
		margin-top: 45px;
		@include mqPc {
			margin-top: 29px;
		}
	}
	&--performers {
		margin-top: 58px;
		@include mqPc {
			margin-top: 50px;
		}
	}
	&--program {
		margin-top: 55px;
		@include mqPc {
			margin-top: 49px;
		}
	}
	&--profile {
		margin-top: 62px;
		@include mqPc {
			margin-top: 59px;
		}
	}
	&--tickets {
		margin-top: 57px;
		@include mqPc {
			margin-top: 47px;
		}
	}
}

.c-news {
	.swiper-wrapper{
		padding-bottom: 20px;
	}
	.swiper-pagination .swiper-pagination-bullet{
		margin-left: 13px;
		margin-right: 13px;
	}

}

.c-section,
.c-news .swiper-wrapper{
	@include mqPc {
		padding-bottom: 20px;
	}
}

.c-about{
	&__heading{
		letter-spacing: -.04em;
		@include mqPc {
			font-size: 3rem;
			line-height: 1.43;
		}
		&+.c-about__text{
			margin-top: 4px;
			@include mqPc {
				margin-top: 10px;
			}
		}
	}
	&__contents {
		margin-top: 11px;
		@include mqPc {
			margin-top: 19px;
		}
	}
}

.c-performers{
	&__list{
		margin-top: 14px;
		@include mqPc {
			margin-top: 17px;
		}
	}
	&__item{
		font-size: 1.4rem;
		@include mqPc {
			font-size: 2rem;
		}
		span{
			small{
				line-height: 1.83;
				font-size: 1.2rem;
				@include mqPc {
					line-height: 1.65;
					font-size: 1.7rem;
				}
			}
		}
		&:nth-of-type(n+2){
			margin-top: 3px;
			@include mqPc {
				margin-top: -2px;
			}
		}
	}
}

.c-program{
	&__list{
		margin-top: 11px;
	}
	&__item{
		padding: 4px 0;
		@include mqPc {
			padding-top: 8px;
			padding-bottom: 2px;
		}
		span{
			padding-top: 1px;
			@include mqPc {
				padding-top: 0;
				line-height: 2.17;
			}
		}
		.u-packing{
			display: inline-block;
			margin-left: -6px;
			color: #fff;
			font-size: 1.4rem;
			line-height: 1.2;
			margin-right: -4px;
			@include mqPc {
				font-size: 2rem;
				line-height: 1.6;
			}
		}
		&:nth-of-type(1){
			@include mqPc {
				padding-bottom: 0;
			}
		}
		&:nth-last-of-type(1){
			border-bottom: 1px solid #fff;
		}
	}
}

.p-program{
	padding-top: 16px;
	padding-bottom: 23px;
	@include mqPc {
		padding-top: 22px;
		padding-bottom: 47px;
	}
	.c-section__heading-deco{
		@include mqPc {
			margin-right: 15px;
		}
	}
}

.c-profile__text{
	margin-top: 1px;
	@include mqPc {
		line-height: 1.93;
	}
}


.-hc .c-profile__list{
	margin-top: 11px;
	@include mqPc {
		margin-top: 20px;
	}
}

.c-profile{
	&__list{
		.c-profile__item{
			@include mqPc {
				padding: 0 0 8px 0;
			}
			&:nth-of-type(n+2){
				margin-top: 27px;
				@include mqPc {
					margin-top: 48px;
				}
			}
		}
		.c-profile__name{
			letter-spacing: normal;
			small{
				display: inline-block;
			}
			span{
				margin-top: -4px;
				letter-spacing: normal;
				@include mqPc {
					margin-top: 0;
				}
			}
		}
	}
	&__contents{
		@include mqPc {
			margin-top: 0;
			padding-left: 24px;
			flex: 0 0 calc(100% - 222px);
		}
	}
	&__image{
		@include mqPc {
			flex: 0 0 222px;
			max-width: 222px;
		}
	}
	&__text{
		margin-top: 3px;
		@include mqPc {
			margin-top: -3px;
		}
	}
}

.c-tickets__heading{
	padding: 10px 0 9px;
	@include mqPc {
		padding-bottom: 7px;
	}

}

.c-tickets__label span{
	@include mqPc {
		letter-spacing: normal;
		font-size: 1.6rem;
	}
}

.c-tickets__block{
	&.release-date{
		margin-top: 15px;
		@include mqPc {
			margin-top: -9px;
		}
	}
}

.c-tickets__heading--sub{
	padding: 5px 0 4px 0;
	@include mqPc {
		padding: 13px 0 14px 0;
	}
	&.-is-23vol01,
	&.-is-23vol02,
	&.-is-23vol03,
	&.-is-23vol04{
		padding-bottom: 8px;
		@include mqPc {
			padding-bottom: 10px;
			padding-top: 16px;
		}
	}
	&+.-hc-color{
		@include mqPc {
			padding-bottom: 19px;
		}
	}
	&+.c-tickets__list{
		.c-tickets__label{
			line-height: 1.56;
			display: inline-block;
			@include mqPc {
				display: flex;
				align-items: center;
				flex-wrap: wrap;
				line-height: 2.68;
			}
		}
	}
}

.c-tickets__list+.c-tickets__heading--sub:not(.-is-23vol01),
.c-tickets__list+.c-tickets__heading--sub:not(.-is-23vol02),
.c-tickets__list+.c-tickets__heading--sub:not(.-is-23vol03),
.c-tickets__list+.c-tickets__heading--sub:not(.-is-23vol04){
	margin-top: 1px;
	@include mqPc {
    margin-top: 9px;
    padding-bottom: 16px;
	}
	&>span{
		@include mqPc {
			font-size: 1.6rem;
		}
	}
	&+.c-tickets__list{
		padding-top: 7px;
		@include mqPc {
			padding-top: 0;
		}
		.c-tickets__item{
			span:nth-of-type(1){
				@include mqPc {
					margin-right: 1.3em;
				}
			}
		}

	}
}

.c-tickets__list{
	&+.c-tickets__heading--sub{
		margin-top: 5px;
		@include mqPc {
			margin-top: 11px;
		}
	}

	.c-tickets__item{
		padding: 3px 0;

		@include mqPc {
			padding: 1px 0 0px;
			line-height: 2.6;
		}

		&.u-pc{
			@include mqPc {
				display: flex;
			}
		}

	}

	&+.c-tickets__heading--sub{
		border-top: none;
		padding-bottom: 6px;
		@include mqPc {
			margin-top: 10px;
			border-top: none;
			padding-top: 14px;
			padding-bottom: 20px;
		}
		&.-is-23vol01{
			@include mqPc {
				margin-top: 12px;
				padding-bottom: 10px;
			}
		}

	}
	&+.c-tickets__list--dots{
		@include mqPc {
			margin-top: 24px;
		}
	}
}
.c-tickets__item{
	&.u-sp{
		&.u-packing{
			letter-spacing: -.04em;
			padding: 8px 0 25px;
		}
	}
	.number{
		@include mqPc {
			margin-top: -10px;
		}
	}
}
.c-tickets{
	&__label{
		line-height: 1.56;
		span{
			line-height: 2.136;
		}
	}
	&__box{
		line-height: 1.46;
		@include mqPc {
			line-height: 2.68;
		}

		span{
			display: inline-block;
			line-height: 1.9;
			@include mqPc {
				margin-left: 0px
			}
		}

		p,
		a{
			@include mqPc {
				margin-right: 24px;
			}
			&.u-sp{
				display: block;
				@include mqPc {
					display: none;
				}
			}
		}

		.code{
			display: block;
			@include mqPc {
				margin-left: 0px;
				display: inline-block;
			}
		}

	}
}

.c-tickets__heading--sub.-hc-color{
	line-height: 1.46;
	padding-bottom: 6px;
}

.c-tikets__text--note{
	padding-top: 9px;
	@include mqPc {
		padding-top: 15px;
	}
}

.c-tickets__list+.c-tickets__list--dots{
	margin-top: 7px;
	@include mqPc {
		margin-top: 14px;
	}
}

.c-tickets__box{
	p{
		&+p{
			margin-top: 0px;
			@include mqPc {
				margin-top: 3px;
			}
		}
	}
}

.c-tickets__list--dots{
	.c-tickets__item{
		&+.c-tickets__item{
			margin-top: 2px;
			padding-top: 0;
			@include mqPc {
				margin-top: -2px;
			}
		}
		.c-tickets__box{
			margin-top: 0;
			span{
				line-height: 2.16;
			}
		}
	}
	.c-tickets__box{
		a{
			letter-spacing: -0.03em;
			line-height: 1.2;
			margin-bottom: 5px;
			@include mqPc {
				line-height: 2.09;
			}

		}
	}
}

.c-tickets__list--dots{
	.c-tickets__item{
		@include mqPc {
			margin-top: 0px;
		}
		.c-tickets__label{
			@include mqPc {
				flex: 0 0 204px;
				min-width: 204px;
				padding-right: 5px;
				line-height: 2.09;
				padding-left: 8px;
			}
		}
		.c-tickets__box{
			@include mqPc {
				flex: 1 0 auto;
				width: auto;
				line-height: 2.09;
				max-width: calc(100% - 220px);
			}

			&.u-pc-flex{
				@include mqPc {
					display: flex;
					flex-wrap: wrap;
				}
			}
		}

	}
	.c-tickets__label{
		&::before {
			@include mqPc {
				width: 13px;
				height: 13px;
				top: -2px;
			}
		}
	}
	.c-tickets__box{
		@include mqPc {
			flex: 1 0 calc(100% - 280px);
			max-width: calc(100% - 220px);
		}

		a{
			@include mqPc {
				line-height: 1.727;
			}
		}
	}
}

.c-tikets__text--note{
	padding-bottom: 14px;
	line-height: 1.65;
	@include mqPc {
		padding-bottom: 17px;
	}
	p{
		@include mqPc {
			letter-spacing: -.02em;
			margin-right: 0;
		}
	}
}

.c-tikets--english{
	margin-top: -2px;
	@include mqPc {
		line-height: 1.7;
		letter-spacing: .05em;
		margin-top: 52px;
	}
	a{
		letter-spacing: -0.03em;
		@include mqPc {
			margin-left: 15px;
		}
	}
	span{
		&.u-pc--i{
			@include mqPc {
				margin-right: 1em;
			}
		}
		&+a{
			margin-left: 0;
		}
	}
	p,
	a{
		font-size: 1.1rem;
    line-height: 1.63;
		font-weight: 600;
		letter-spacing: -0.01em;
		@include mqPc {
			font-size: 1.8rem;

			line-height: 1.777;
		}
	}
}
.c-section--movie{
	margin-top: 63px;
	@include mqPc {
		margin-top: 21px;
	}
	&+.c-section--link{
		@include mqPc {
			margin-top: 122px;
		}
	}
}

.p-movie__list__text{
	@include mqPc {
		margin-top: 21px;
	}
}

.c-stage-notice{
	@include mqPc {
		margin-top:111px;
		line-height: 1.846;
	}

	.c-text{
		@include mqPc {
			padding-right: 0;

		}
	}

	p{
		&.-organizer{
			@include mqPc {
				margin-top: 17px;
			}
		}
		&.-copy{
			@include mqPc {
				margin-top: 1px;
			}
		}
	}
}

.p-other-text{
	@include mqPc {
		display: flex;
		flex-wrap: wrap;
		padding-bottom: 6px;
	}
	.p-access{
		@include mqPc {
			flex: 1 1 100%;
		}
		&__text{
			@include mqPc {
				font-size: 1.4rem;
			}
			p{
				@include mqPc {
				font-size: 22px;
				}
			}
		}
	}
	&__text{
		margin-top: 31px;
		letter-spacing: -0.08em;
		@include mqPc {
			flex: 1 1 auto;
			margin-top: 16px;
			font-size: 1.4rem;
		}
		small{
			font-size: 9px;
			line-height: calc(35 / 18);
			font-weight: 300;
			display: block;
			margin-top: 0px;
			@include mqPc {
				font-size: 11px;
				line-height: calc(19 / 11);
			}
		}
	}
	&__image{
		margin-top: 10px;
		@include mqPc {
			display: block;
			flex: 1 0 164px;
			align-self: flex-end;
			padding-bottom: 21px;
			margin-top: 0;
		}
		img{
			width: 122px;
		}
	}
}

.c-stage-notice{
	margin-top: 57px;
	@include mqPc {
		margin-top: 116px;
	}
}


.p-access{
	&__text{
		font-weight: 600;
		p{
			&.-hc-color{
				margin-top: 1em !important;
				font-size: 11px;
				@include mqPc {
					font-size: 22px;
				}
			}
		}
	}
}


.u-symbol-top{
	font-size: 10px !important;
	line-height: 1 !important;
	display: inline-block !important;
	margin-top: -10px !important;
}

.-is-24vol01,
.-is-24vol02,
.-is-24vol03,
.-is-24vol04{
	.l-container{
		padding-bottom: 8px;
		@include mqPc {
			padding-bottom: 23px;
		}
	}

	.c-performers__list{
		@include mqPc {
			margin-top: 14px;
			padding-right: 17px;
		}
	}

	.c-performers__item{
		flex-wrap: wrap;
		@include mqPc {
			flex-wrap: wrap;
			line-height: 1.65;
		}
		span{
			display: block;
			@include mqPc {
				flex: 1 0 45.5%;
				max-width: unset;
			}
		}
		&>p{
			flex: 1 1 100%;
			@include mqPc {
				flex: 1 0 410px;
			}
		}
	}

	.c-performers__part{
		display: flex;
		align-items: center;
		font-size: 1rem;
		line-height: 2;
		@include mqPc {
			font-size: 1.8rem;
			line-height: calc(33 / 18);
		}
		&::before{
			content: "";
			display: block;
			background-color: #9f8b49;
			width: 7px;
			height: 7px;
			margin-left: 2.5px;
			margin-right: 1.5px;
			@include mqPc {
				width: 13px;
				height: 13px;
				margin-right: 3px;
			}
		}
	}

	.c-performers__item--big{
		font-size: 1.5rem;
		line-height: calc(45 / 30);
		@include mqPc {
			font-size: 2.4rem;
			line-height: calc(33 / 24);
			padding-bottom: 9px;
		}
		span{
			font-size: 1.2rem;
			line-height: 1.66;
			@include mqPc {
				display: inline-block;
				margin-left: 0.8em;
				font-size: 2.4rem;
				line-height: calc(33 / 24);
			}
		}
	}

}

.c-media__heading{
	img{
		width: 190px;
		@include mqPc {
			width: 275px;
		}
	}
}

.c-media__text{
	@include mqPc {
		margin-top: 3px;
	}
}
